import { LoadingScreen } from 'src/components/loading-screen';
import {
  Navigate,
  Outlet,
  RouterProvider,
  createBrowserRouter,
} from 'react-router-dom';
import { Suspense, lazy } from 'react';

import AuthClassicLayout from 'src/layouts/auth/classic';
import CompactLayout from 'src/layouts/compact';
import DashboardLayout from 'src/layouts/dashboard';
import { AuthGuard, GuestGuard } from 'src/auth/guard';
import SettingsListPage from 'src/pages/settings/settings-list-page';

const AuthPage = lazy(() => import('src/pages/auth'));
const AnalyticsPage = lazy(() => import('src/pages/analytics/analytics-page'));
const UserListPage = lazy(() => import('src/pages/users/user-list-page'));
const UserDetailPage = lazy(() => import('src/pages/users/user-detail-page'));
const LocationCountryPage = lazy(
  () => import('src/pages/locations/location-country-page')
);
const LocationProvincePage = lazy(
  () => import('src/pages/locations/location-province-page')
);
const LocationCityPage = lazy(
  () => import('src/pages/locations/location-city-page')
);
const LocationAreaPage = lazy(
  () => import('src/pages/locations/location-area-page')
);
const PropertiesPage = lazy(
  () => import('src/pages/property/property-list-page')
);
const PropertyLayout = lazy(
  () => import('src/modules/properties/layouts/property-layout')
);
const OverviewPage = lazy(
  () => import('src/pages/property/property-overview-page')
);
const PropertyCreatePage = lazy(
  () => import('src/pages/property/property-create-page')
);
const PropertyEditPage = lazy(
  () => import('src/pages/property/property-edit-page')
);
const OnelinkPage = lazy(() => import('src/pages/onelink/onelink-page'));
const Costs = lazy(() => import('src/modules/settings/Costs/Costs'));
const Page404 = lazy(() => import('src/pages/404'));

const router = createBrowserRouter([
  // Auth routes
  {
    path: 'auth',
    element: (
      <GuestGuard>
        <AuthClassicLayout>
          <AuthPage />
        </AuthClassicLayout>
      </GuestGuard>
    ),
  },

  // Dashboard routes
  {
    path: '/',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      {
        index: true,
        element: <AnalyticsPage />,
      },
      {
        path: 'users',
        element: <UserListPage />,
      },
      {
        path: 'users/:sub',
        element: <UserDetailPage />,
      },
      {
        path: 'locations',
        children: [
          {
            index: true,
            element: <Navigate to="countries" replace />,
          },
          {
            path: 'countries',
            element: <LocationCountryPage />,
          },
          {
            path: 'provinces',
            element: <LocationProvincePage />,
          },
          {
            path: 'cities',
            element: <LocationCityPage />,
          },
          {
            path: 'areas',
            element: <LocationAreaPage />,
          },
        ],
      },
      {
        path: 'properties',
        element: <PropertiesPage />,
      },
      {
        path: 'properties/add',
        element: <PropertyCreatePage />,
      },
      {
        path: 'properties/:id',
        element: <PropertyLayout />,
        children: [
          { index: true, element: <OverviewPage /> },
          { path: 'edit', element: <PropertyEditPage /> },
        ],
      },
      {
        path: 'onelink',
        element: <OnelinkPage />,
      },
      {
        path: 'settings',
        element: <SettingsListPage />,
      },
      {
        path: 'settings/costs',
        element: <Costs />,
      },
    ],
  },

  // Main routes
  {
    element: (
      <CompactLayout>
        <Outlet />
      </CompactLayout>
    ),
    children: [{ path: '404', element: <Page404 /> }],
  },

  // No match 404
  { path: '*', element: <Navigate to="/404" replace /> },
]);

export function AppRouter() {
  return <RouterProvider router={router} />;
}
