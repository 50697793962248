import { useAuth0 } from '@auth0/auth0-react';
import { useMemo } from 'react';

export function useAuthRoles() {
  const { user } = useAuth0();

  return useMemo(() => (user?._roles ?? []) as string[], [user]);
}

export function useAuthRolesCheck(role: string[]) {
  const roles = useAuthRoles();

  return useMemo(() => role.some((r) => roles.includes(r)), [role, roles]);
}

export function useIsAnalyticsRole() {
  return useAuthRolesCheck(['Analytics']);
}

export function useIsOnelinksRole() {
    return useAuthRolesCheck(['Onelinks']);
  }

export function useIsPropertiesRole() {
  return useAuthRolesCheck(['Properties']);
}
