import { useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';
// components
import SvgColor from 'src/components/svg-color';
import Iconify from 'src/components/iconify';
import { useAuthRoles } from 'src/hooks/use-auth-roles';

// ----------------------------------------------------------------------

const icon = (name: string, iconify?: boolean) => {
  if (iconify) return <Iconify icon={name} />;
  return (
    <SvgColor
      src={`/assets/icons/navbar/${name}.svg`}
      sx={{ width: 1, height: 1 }}
    />
  );
};

const ICONS = {
  user: icon('ic_user'),
  dash: icon('ic_dash'),
  job: icon('ic_job'),
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
  settings: icon('ic_settings'),
};

// ----------------------------------------------------------------------

export function useNavData() {
  const roles = useAuthRoles();

  const data = useMemo(
    () => [
      // OVERVIEW
      // ----------------------------------------------------------------------
      {
        items: [
          ...(roles.includes('Analytics')
            ? [
                {
                  title: 'Analytics',
                  path: paths.dashboard.root,
                  icon: ICONS.analytics,
                  // children: [
                  //   {
                  //     title: 'Add',
                  //     path: `${paths.dashboard.properties}/add`,
                  //     hidden: true,
                  //   },
                  // ],
                },
              ]
            : []),
          {
            title: 'Users',
            path: paths.dashboard.users,
            icon: ICONS.user,
          },
          {
            title: 'OneLink',
            path: paths.dashboard.onelink,
            icon: ICONS.external,
          },
          ...(roles.includes('Properties')
            ? [
                {
                  title: 'Properties',
                  path: paths.dashboard.properties,
                  icon: ICONS.ecommerce,
                },
              ]
            : []),
          {
            title: 'Locations',
            path: paths.dashboard.locations,
            icon: ICONS.tour,
            children: [
              {
                title: 'Countries',
                path: `${paths.dashboard.locations}/countries`,
              },
              {
                title: 'Provinces',
                path: `${paths.dashboard.locations}/provinces`,
              },
              {
                title: 'Cities',
                path: `${paths.dashboard.locations}/cities`,
              },
              {
                title: 'areas',
                path: `${paths.dashboard.locations}/areas`,
              },
            ],
          },
          ...(roles.includes('Properties')
            ? [
                {
                  title: 'Settings',
                  path: paths.dashboard.settings,
                  icon: ICONS.settings,
                  children: [
                    {
                      title: 'Costs',
                      path: `${paths.dashboard.settings}/costs`,
                    },
                  ],
                },
              ]
            : []),
        ],
      },
    ],
    [roles]
  );

  return data;
}
